import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const INITIAL_STATE = {
  errors: null,
  warnings: null,
  success: null,
};

export default {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    errors: (state) => state.errors,
  },
  mutations: {
    ADD_ERROR(state, newError) {
      state.errors = newError;
    },
    CLEAR_ERROR(state) {
      state.errors = [];
    },
  },
  actions: {
    addError({ commit }, newError) {
      commit("ADD_ERROR", newError);
    },
    clearError({ commit }) {
      commit("CLEAR_ERROR");
    },
  },
};
