<template>
  <div>
    <router-view></router-view>
    <SnackbarErrorVue></SnackbarErrorVue>
  </div>
</template>

<script>
import SnackbarErrorVue from "./components/SnackbarError.vue";

export default {
  components: {
    SnackbarErrorVue,
  },
};
</script>

<style>
.input-error {
  font-size: 0.8rem;
  color: red;
  margin-top: 0.5rem;
  min-height: 1rem;
}

.grid-center-h-v {
  display: grid;
  place-items: center;
  min-height: 20vh;
  width: 100%;
}

.mobile-view {
  display: initial;
}

.mobile-view-full {
  width: initial;
}

.disabled-section {
  opacity: 0.5;
}

.disabled-section:hover {
  cursor: not-allowed;
}

.v-select__selections {
  flex-wrap: nowrap !important;
}

.only-mobile-view {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobile-view {
    display: none;
  }

  .only-mobile-view {
    display: block;
  }

  .mobile-view-full {
    width: 100%;
  }
}
</style>
