import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const INITIAL_STATE = {
  cognitoPool: null,
  enterprise: null,
  user: {
    isLogged: false,
    isLogging: false,
    cognitouser: null,
  },
  awsExports: {},
};

export default {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    isLogged: (state) => state.user.isLogged,
    isLogging: (state) => state.user.isLogging,
    cognitoUser: (state) => state.user.cognitouser,
    userId: (state) =>
      state.user.cognitouser.signInUserSession.idToken.payload["custom:id"],
    userRole: (state) =>
      state.user.cognitouser.signInUserSession.idToken.payload[
        "cognito:groups"
      ][0],
    enterprise: (state) => state.enterprise,
    cognitoPool: (state) => state.cognitoPool,
    awsExports: (state) => state.awsExports,
    apiurl: (state) => `${state.cognitoPool.awsAPI}/v2`,
    companyLogo: (state) => state.cognitoPool.logo,
    mainColor: (state) => state.cognitoPool.maincolor ?? "#fff",
  },
  mutations: {
    SET_COGNITO_POOL(st, value) {
      st.cognitoPool = value;
    },
    SETUSER(st, value) {
      // console.log(`M::SETUSER: ${JSON.stringify(value)}`);
      st.user.cognitouser = value;
    },
    SETLOGGED(st, value) {
      st.user.isLogged = value;
    },
    SETLOGGING(st, value) {
      st.user.isLogging = value;
    },
    SETENTERPRISE(st, value) {
      st.enterprise = value;
    },
    SETAWS(state, value) {
      state.awsExports = value;
    },
    LOGOUT(state) {
      state.cognitoPool = null;
      state.enterprise = null;
      state.user = {
        isLogged: false,
        isLogging: false,
        cognitouser: null,
      };
      state.awsExports = {};
    },
  },
  actions: {
    setCognitoPool({ commit }, value) {
      commit("SET_COGNITO_POOL", value);
    },
    setUser({ commit }, value) {
      commit("SETUSER", value);
    },
    setLogged({ commit, dispatch }, value) {
      if (value) {
        dispatch("IS_BARRIERS");
      }
      commit("SETLOGGED", value);
    },
    setLogging({ commit }, value) {
      commit("SETLOGGING", value);
    },
    setEnterprise({ commit }, value) {
      commit("SETENTERPRISE", value);
    },
    setAws({ commit }, value) {
      commit("SETAWS", value);
    },
    logoutUser({ commit }) {
      commit("LOGOUT");
    },
  },
};
