import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// -- layouts
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout.vue";

// -- Dynapark
const DynaparkAuthentication = () =>
  import("../views/Dynapark/Authentication.vue");
const DynaparkDashboard = () => import("../views/Dynapark/Dashboard.vue");
const DynaparkTableauDeBord = () =>
  import("../views/Dynapark/TableauDeBord.vue");
const DynaparkTableauDeBordDetails = () =>
  import("../views/Dynapark/TableauDeBordDetails.vue");
const ReservationDetails = () =>
  import("../views/Dynapark/ReservationDetails.vue");
const Users = () => import("../views/Dynapark/Users.vue");

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLogged) {
    if (
      ["/dashboard", "/users"].includes(to.path) &&
      store.getters.userRole !== "admin"
    ) {
      next("/home");
      return;
    }
    next();
    return;
  }

  next("/login");
};

const routes = [
  {
    path: "/",
    redirect: "/login",
    name: "Dynapark Authentication",
    component: AuthCoverLayout,
    children: [
      {
        path: "login",
        name: "Dynapark authentication",
        component: DynaparkAuthentication,
      },
    ],
  },
  {
    path: "/",
    name: "Dashboard",
    redirect: "/home",
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "home",
        name: "Dynapark Home",
        component: DynaparkDashboard,
      },
      {
        path: "dashboard",
        name: "Dynapark Tableau de bord",
        component: DynaparkTableauDeBord,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "dashboard/:id",
        name: "DynaparkTableauDeBordDetails",
        component: DynaparkTableauDeBordDetails,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "reservations/:id",
        name: "ReservationDetails",
        component: ReservationDetails,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "users",
        name: "Dynapark Users",
        component: Users,
        beforeEnter: ifAuthenticated,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
