import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export const INITIAL_STATE = {
  isParkingGetBarrier: false,
  parkings: [],
};

export default {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    isParkingGetBarrier: (state) => state.isParkingGetBarrier,
    parkings: (state) => state.parkings,
  },
  mutations: {
    UPDATE_PARKINGS(state, parkings) {
      state.parkings = parkings;
    },
    UPDATE_PARKING_BARRIERS(state, barriers) {
      state.isParkingGetBarrier = barriers;
    },
  },
  actions: {
    IS_BARRIERS({ commit, getters }) {
      axios.get(`${getters.apiurl}/parkings`).then((res) => {
        commit("UPDATE_PARKINGS", res.data);
        const barriers = res.data.some(
          (parking) => parking.barriers && parking.barriers.length > 0
        );
        commit("UPDATE_PARKING_BARRIERS", barriers);
      });
    },
  },
};
