import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const INITIAL_STATE = {
  demoContent: "Start vuex store 👍",
};

export default {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    demoContent: (state) => state.demoContent,
  },
  mutations: {
    UPDATE_DEMO_CONTENT(state, newContent) {
      state.demoContent = newContent;
    },
  },
  actions: {
    UPDATE_DEMO_CONTENT({ commit }, newContent) {
      commit("UPDATE_DEMO_CONTENT", newContent);
    },
  },
};
