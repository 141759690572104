<template>
  <div class="text-center">
    <!-- <v-btn dark color="red darken-2" @click="snackbar = true">
      Open Snackbar
    </v-btn> -->

    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      class="snackbar-shadow"
    >
      {{ alerts }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackbarError",

  data: () => ({
    multiLine: true,
    snackbar: false,
    text: `Display API errors here!`,
  }),

  computed: {
    alerts() {
      return this.$store.getters.errors;
    },
  },

  watch: {
    alerts() {
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>
