import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import demo from "./modules/demo";
import auth from "./modules/auth";
import alerts from "./modules/alerts";
import users from "./modules/users";
import parkings from "./modules/parkings";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ key: "dynapark-app" })],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    alerts,
    users,
    parkings,
    demo,
  },
});
