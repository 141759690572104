import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const INITIAL_STATE = {
  users: [],
};

export default {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    users: (state) => state.users,
  },
  mutations: {
    SET_USERS(state, newContent) {
      state.users = newContent;
    },
  },
  actions: {
    SET_USERS({ commit }, users) {
      commit("SET_USERS", users);
    },
  },
};
