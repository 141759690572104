/*
=========================================================
* Vuetify Soft UI Dashboard PRO - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import Vuelidate from "vuelidate";
import Auth from "@aws-amplify/auth";
import axios from "axios";

if (store.getters.awsExports) {
  Auth.configure(store.getters.awsExports);
}

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Vuelidate);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

// -- INTERCEPTOR
axios.interceptors.request.use(function (config) {
  // console.log("TOKEN USER", store.getters.isLogged);
  if (store.getters.isLogged) {
    return Auth.currentSession()
      .then((session) => {
        // User is logged in. Set auth header on all requests
        // console.log("SESSION: ", session);
        config.headers.Authorization = session.idToken.jwtToken;
        // console.log("header token (idToken): ", config.headers.Authorization);
        return Promise.resolve(config);
      })
      .catch((error) => {
        // No logged-in user: don't set auth header - got to login page
        console.error("Error on request: ", error);
        if (
          [
            "No current user",
            "Refresh Token has expired",
            "NotAuthorizedException: Refresh Token has expired",
          ].includes(error)
        ) {
          store.dispatch("logoutUser");
          router.push("/login");
        }
      });
  }
  return Promise.resolve(config);
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.error("Error on response: ", error);
    if (
      [
        "No current user",
        "Refresh Token has expired",
        "NotAuthorizedException: Refresh Token has expired",
      ].includes(error) ||
      (error.response && error.response.status === 401)
    ) {
      store.dispatch("logoutUser");
      router.push("/login");
    }
    return Promise.reject(error.response);
  }
);
